import * as React from "react"
import {Helmet} from "react-helmet"
import Header from "../components/header/header";
import Layout from "../components/layout/layout";
import Footer from "../components/footer/footer";
import Contact from "../components/contact/contact";

const ContactPage = () => {
    return (<>
        <Helmet>
            <meta charSet="utf-8"/>
            <html lang="en"/>
            <title>Contact us - Recoded Recruitment</title>
            <meta name="description"
                  content="Hiring top talent in tech just got easy. Partner with us for expert tech industry recruitment. Choose from our Search, Contingent, or Embedded services for tailored solutions to match your needs."/>
            <meta name="theme-color" content="#F6F4FB"></meta>
            <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>
        </Helmet>
        <Layout header={<Header transparent={true}/>}
                footer={<Footer/>}>
            <Contact/>
        </Layout>
    </>);
}

export default ContactPage
