import * as styles from "./contact.module.scss";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import TelephoneSVG from "../../svg/pointers/telephone.svg";
import EmailSVG from "../../svg/pointers/email.svg";
import ArrowSVG from "../../svg/anchorArrow.svg";
import { Link } from "gatsby";

export default function Contact() {
    const [ validated, setValidated ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>('');
    const [ contactNo, setContactNo ] = useState<string>('');

    const [ successVisible, setSuccessVisible ] = useState(false);
    const [ errorVisible, setErrorVisible ] = useState(false);

    const handleFormSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (!form.checkValidity()) {
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            console.log(process.env.GATSBY_WORKER_URL);
            const request = new Request(process.env.GATSBY_WORKER_URL!, {
                method: "POST",
                body: formData
            });

            const onError = () => {
                setErrorVisible(true);
                setTimeout(() => {
                    setErrorVisible(false);
                }, 5000);
            }

            try {
                let response = await fetch(request);
                if (response.status === 200) {
                    setSuccessVisible(true);
                    setTimeout(() => {
                        setSuccessVisible(false);
                    }, 5000);
                } else {
                    onError();
                }
            } catch {
                onError();
            }
        }

        setValidated(true);
    }

    return <div className={styles.wrapper}>
        <div className={styles.card}>
            <Row className={styles.contactContainer}>
                <Col lg={6}>
                    <div>
                        <div className={styles.getInTouch}>Get in touch</div>
                        <br />
                        <div className={styles.mainTitle}>The best partnerships start with a conversation</div>
                        <br />
                        <div className={styles.subTitle}>
                            We’re always happy to chat about how we can support your hiring
                            needs. Drop us a line, or complete our short form and we’ll be in touch.
                        </div>
                    </div>
                    <div className={styles.contactData}>
                        <div className={styles.contactEntry}>
                            <TelephoneSVG className={styles.pointerIcon} />
                            <div className={styles.text}>+44 1202 138426</div>
                        </div>
                        <br />
                        <div className={styles.contactEntry}>
                            <EmailSVG className={styles.pointerIcon} />
                            <div className={styles.text}>enquiries@recodedrecruitment.com</div>
                        </div>
                    </div>
                </Col>
                <Col lg={1} />
                <Col lg={5}>
                    {successVisible && <Alert variant={"success"}>Success! Talk to you soon!</Alert>}
                    {errorVisible && <Alert variant={"danger"}>Something went wrong.</Alert>}
                    <Form noValidate validated={validated} className={styles.form} onSubmit={handleFormSubmit}>
                        <Row>
                            <Col xs={12} lg={6} className={styles.inlineColFirst}>
                                <Form.Control
                                    className={styles.input}
                                    required
                                    name="firstName"
                                    type="text"
                                    placeholder="First name*"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Please provide first name
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} className={styles.inlineCol}>
                                <Form.Control
                                    className={styles.input}
                                    required
                                    name="lastName"
                                    type="text"
                                    placeholder="Last name*"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Please provide last name
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6} className={styles.inlineColFirst}>
                                <Form.Control
                                    className={styles.input}
                                    required
                                    name="email"
                                    type="email"
                                    placeholder="Email*"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {email.length
                                        ? 'Email has incorrect format'
                                        : 'Please provide email'}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={12} lg={6} className={styles.inlineCol}>
                                <Form.Control
                                    className={styles.input}
                                    required
                                    name="contactNumber"
                                    type="text"
                                    pattern='^[\d|\s|\+|\(|\)]+$'
                                    placeholder="Contact no*"
                                    onChange={(e) => setContactNo(e.target.value)}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {contactNo.length
                                        ? 'Contact number has incorrect format'
                                        : 'Please provide contact number'}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Control
                                    className={styles.input}
                                    required
                                    name="message"
                                    type="text"
                                    placeholder="Message*"
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Please provide message
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        {/* <div className={styles.uploadContainer}>
                            <div className={styles.text}>Upload CV</div>
                            <Form.Control type="file" size="sm" name="cv" className={styles.uploadControl} />
                                    </div> */}
                        <div className={styles.radio}>
                            <Form.Check
                                type='radio'
                                required
                            />
                            <Form.Control.Feedback type='invalid'>
                                Please accept our Privacy Policy
                            </Form.Control.Feedback>
                            <div className={styles.text}>
                                I have read and accept our
                            </div>
                            <Link to='/privacy' className={styles.link}>
                                Privacy Policy*
                            </Link>
                        </div>

                        <div className="cf-turnstile" data-sitekey={process.env.GATSBY_SITE_KEY} data-theme="light"></div>
                        <Button type="submit" className={styles.button}>
                            <div className={styles.text}>Submit</div>
                            <ArrowSVG className={styles.arrow} />
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    </div>
}
